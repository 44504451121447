<mat-form-field>
  <!-- <mat-label>Search for a resource</mat-label> -->
  <input
    matInput
    placeholder="Search for a resource"
    [matAutocomplete]="auto"
    [formControl]="inputControl"
    (focus)="onInputFocused()" />
  <mat-autocomplete
    #autoComplete
    #auto="matAutocomplete"
    [displayWith]="displayResource"
    (optionsScroll)="onScroll()"
    (optionSelected)="onResourceSelected($event)">
    <mat-option *ngIf="resourcesSearchNoResults && !resourcesSearchResultsLoading" [disabled]="true">
      No results found
    </mat-option>
    <mat-option
      *ngIf="!resourcesSearchNoResults && !resourcesSearchResultsLoading && resourcesSearchResultsCount"
      [disabled]="true">
      {{ resourcesSearchResultsCount }} results found
    </mat-option>

    <mat-option *ngFor="let res of resourcesSearchResults" [value]="res"> {{ res?.label }}</mat-option>
    <mat-option *ngIf="resourcesSearchResultsLoading" [disabled]="true" class="loader">
      <app-progress-indicator />
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
