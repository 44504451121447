<app-progress-indicator *ngIf="isLoading$ | async" />
<div *ngIf="(readProject$ | async) as project" class="content large middle">
  <div>
    <p>Project Description</p>
  </div>
  <!--  mobile version: status and edit icon displayed before the title -->
  <div class="app-toolbar-mobile">
    <span class="app-toolbar-action-edit" *ngIf="userHasPermission$ | async">
      <button mat-icon-button *ngIf="project.status" class="right" color="primary">
        <mat-icon>edit_square</mat-icon>
        Edit project description
      </button>
    </span>
  </div>

  <!-- desktop and tablet version -->
  <div class="app-toolbar transparent more-space-bottom">
    <div class="app-toolbar-row">
      <h3 class="mat-body subtitle">Project {{ project.shortcode }} | {{ project.shortname | uppercase }}</h3>
      <span class="fill-remaining-space"></span>
      <span class="app-toolbar-action">
        <button
          mat-button
          *ngIf="(userHasPermission$ | async) && project.status"
          class="right"
          [routerLink]="RouteConstants.projectEditRelative"
          color="primary">
          <mat-icon>edit_square</mat-icon>
          Edit project description
        </button>
      </span>
    </div>
  </div>
  <!-- description -->
  <div class="description-rm">
    <div>
      <p class="project-longname">{{ project.longname }}</p>
    </div>
    <section class="project description" *ngFor="let desc of sortedDescriptions$ | async">
      <div [innerHtml]="desc.value"></div>
    </section>

    <mat-divider *ngIf="project.keywords.length > 0"></mat-divider>

    <!-- keywords -->
    <section class="project keywords">
      <mat-chip-listbox>
        <mat-chip *ngFor="let k of project.keywords">{{ k }}</mat-chip>
      </mat-chip-listbox>
    </section>
  </div>
</div>
