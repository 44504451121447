<app-progress-indicator *ngIf="isProjectsLoading$ | async" />

<div *ngIf="(isProjectsLoading$ | async) === false">
  <div *ngIf="isCurrentProjectAdminOrSysAdmin$ | async" class="content large middle">
    <!-- add user to the project -->
    <app-add-user
      *ngIf="(project$ | async)?.status && ((isCurrentProjectAdminOrSysAdmin$ | async) === true)"
      [projectUuid]="projectUuid"
      #addUserComponent />

    <!-- main content: list of project members -->

    <div class="users-list">
      <!-- list of active users -->
      <app-users-list
        [project]="project$ | async"
        [list]="activeProjectMembers$ | async"
        [status]="true"
        (refreshParent)="refresh()" />

      <!-- list of inactive users -->
      <app-users-list
        [project]="project$ | async"
        [list]="inactiveProjectMembers$ | async"
        [status]="false"
        (refreshParent)="refresh()" />
    </div>
  </div>

  <div *ngIf="(isCurrentProjectAdminOrSysAdmin$ | async) === false" class="content large middle">
    <app-status [status]="403" />
  </div>
</div>
