<!-- When withMultipleSelection is false and user can select only one resource at a time to view -->
<mat-list class="resource-list">
  <div
    class="link"
    [class.selected-resource]="selectedResourceIdx.indexOf(i) > -1"
    [class.border-bottom]="!last"
    *ngFor="
            let resource of resources;
            let i = index;
            let last = last
        ">
    <mat-list-item>
      <div matListItemTitle class="res-list-item-container">
        <div
          class="res-list-item-content"
          data-cy="resource-list-item"
          (click)="
                        selectResource({
                            checked: true,
                            resIndex: i,
                            resId: resource.id,
                            resLabel: resource.label,
                            isCheckbox: false
                        })
                    ">
          <mat-icon matListItemIcon>{{ getIcon(resource) }}</mat-icon>
          <div matLine class="res-list-item-text">
            <p matLine class="res-class-label shorten-long-text">
              {{ resource.entityInfo.classes[resource.type].label }}
            </p>
            <h3 matLine class="res-class-value shorten-multiline-text">{{ resource.label }}</h3>
            <div matLine *ngFor="let prop of resource.properties | keyvalue">
              <div matLine *ngFor="let val of prop.value; let i = index">
                <span class="res-prop-label shorten-long-text">
                  {{ resource.entityInfo.properties[val.property].label }}
                </span>
                <div class="shorten-long-text" [innerHtml]="val.strval | internalLinkReplacer | addTargetBlank"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- if withMultipleSelection is true, we display checkbox -->
        <div class="res-checkbox">
          <mat-checkbox
            #ckbox
            *ngIf="withMultipleSelection"
            id="{{ i }}"
            (change)="
                            selectResource({
                                checked: $event.checked,
                                resIndex: i,
                                resId: resource.id,
                                resLabel: resource.label,
                                isCheckbox: true
                            })
                        ">
          </mat-checkbox>
        </div>
      </div>
    </mat-list-item>
  </div>
</mat-list>
