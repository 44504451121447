<app-dialog-header
  [title]="ontologyName ? ontologyName : ('form.ontology.title.create' | translate)"
  [subtitle]="ontologyName ? ('form.ontology.title.edit' | translate) : ''" />
<form [formGroup]="ontologyForm" class="form-content">
  <app-common-input
    [control]="ontologyForm.controls.name"
    [validatorErrors]="[ontoNamePatternErrorMsg, ontoNameExistsErrorMsg]"
    [label]="'Set a unique name'"
    data-cy="name-input" />
  <app-common-input [control]="ontologyForm.controls.label" [label]="'Label'" data-cy="label-input" />
  <mat-form-field class="large-field">
    <mat-label>Comment</mat-label>
    <textarea
      matInput
      data-cy="comment-textarea"
      [formControl]="ontologyForm.controls.comment"
      [cdkTextareaAutosize]="true"
      [cdkAutosizeMinRows]="6"
      [cdkAutosizeMaxRows]="12">
    </textarea>
  </mat-form-field>
</form>

<div mat-dialog-actions align="end">
  <button color="primary" mat-button mat-dialog-close>{{ 'form.action.cancel' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    appLoadingButton
    [isLoading]="loading"
    [disabled]="!ontologyForm?.valid || loading"
    (click)="submitData()"
    data-cy="submit-button">
    {{ 'form.action.submit' | translate }}
  </button>
</div>
