<div *ngIf="isSysAdmin$ | async; else notAllowed">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="navigation tab-bar margin-from-top">
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <a
      mat-tab-link
      *ngFor="let link of links"
      [routerLink]="link.url"
      (click)="activeLink = link.name"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive">
      <mat-icon class="tab-icon">{{ link.icon }}</mat-icon>
      {{ link.name }}
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>

<ng-template #notAllowed>
  <div class="content large middle">
    <app-status [status]="403" />
  </div>
</ng-template>
