<div appDisableContextMenu [class.cinema]="cinemaMode">
  <app-status [comment]="videoError" [url]="src.fileValue.fileUrl" [representation]="'video'" *ngIf="videoError" />

  <div style="margin-bottom: -4px" class="video-container">
    <video
      style="width: 100%"
      id="video"
      *ngIf="video; else loading"
      [src]="video"
      type="video/mp4"
      preload="auto"
      [muted]="false"
      (canplay)="onVideoPlayerReady()"
      (click)="videoPlayer.togglePlay()"
      (error)="handleVideoError($event)"></video>
    <app-video-overlay *ngIf="false" />
  </div>

  <div class="preview" #preview>
    <app-video-preview
      *ngIf="false"
      [src]="src"
      [time]="previewTime"
      [fileInfo]="fileInfo"
      (loaded)="displayPreview(!$event)" />
  </div>

  <mat-toolbar *ngIf="false">
    <mat-toolbar-row>
      <app-av-timeline
        [value]="0"
        [min]="0"
        [max]="duration"
        [resized]="cinemaMode"
        (move)="updatePreview($event)"
        (mouseenter)="displayPreview(true)"
        (dimension)="timelineDimension = $event"
        (mouseleave)="displayPreview(false)"
        (changed)="videoPlayer.navigate($event)"
        [disabled]="!isPlayerReady" />
    </mat-toolbar-row>
  </mat-toolbar>
  <app-media-slider
    *ngIf="isPlayerReady"
    [max]="videoPlayer.duration()"
    [currentTime]="myCurrentTime"
    (afterNavigation)="videoPlayer.navigate($event)" />
  <app-segments-display
    *ngIf="segmentsService.segments.length > 0 && duration > 0"
    [segments]="segmentsService.segments"
    [videoLengthSecs]="duration" />
  <app-video-toolbar
    *ngIf="fileInfo && isPlayerReady"
    [src]="src"
    [parentResource]="parentResource"
    [fileInfo]="fileInfo"
    [isAdmin]="isAdmin"
    [(cinemaMode)]="cinemaMode" />
</div>

<ng-template #loading>
  <app-progress-indicator />
</ng-template>
