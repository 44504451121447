<div class="advanced-search-container">
  <div class="header">
    <div class="title">
      <p>Advanced Search</p>
    </div>
    <div *ngIf="previousSearchObject" class="action-buttons right-margin">
      <button class="previous-search-button" mat-button (click)="loadPreviousSearch()">Use Previous Search</button>
    </div>
  </div>
  <div
    *ngIf="{
            ontologies: ontologies$ | async,
            ontologiesLoading: ontologiesLoading$ | async,
            resourceClasses: resourceClasses$ | async,
            resourceClassesLoading: resourceClassesLoading$ | async,
            searchButtonDisabled: searchButtonDisabled$ | async,
            propertyFormList: propertyFormList$ | async,
            propertiesOrderByList: propertiesOrderByList$ | async,
            properties: properties$ | async,
            propertiesLoading: propertiesLoading$ | async,
            filteredProperties: filteredProperties$ | async,
            addButtonDisabled: addButtonDisabled$ | async,
            resetButtonDisabled: resetButtonDisabled$ | async,
            selectedOntology: selectedOntology$ ? (selectedOntology$ | async) : undefined,
            selectedResourceClass: selectedResourceClass$ ? (selectedResourceClass$| async) : undefined,
            matchResourceClassesLoading: matchResourceClassesLoading$ | async,
            resourcesSearchResultsLoading: resourcesSearchResultsLoading$ | async,
            resourcesSearchResultsCount: resourcesSearchResultsCount$ | async,
            resourcesSearchResults: resourcesSearchResults$ | async,
            resourcesSearchNoResults: resourcesSearchNoResults$ | async,
            orderByButtonDisabled: orderByButtonDisabled$ | async,
        } as asyncData">
    <app-order-by
      [orderByList]="asyncData.propertiesOrderByList"
      [orderByDisabled]="asyncData.orderByButtonDisabled"
      (emitPropertyOrderByChanged)="handlePropertyOrderByChanged($event)" />
    <app-ontology-resource-form
      [ontologies]="asyncData.ontologies"
      [ontologiesLoading]="asyncData.ontologiesLoading"
      [resourceClasses]="asyncData.resourceClasses"
      [resourceClassesLoading]="asyncData.resourceClassesLoading"
      [selectedOntology]="asyncData.selectedOntology"
      [selectedResourceClass]="asyncData.selectedResourceClass"
      (emitSelectedOntology)="handleSelectedOntology($event)"
      (emitSelectedResourceClass)="handleSelectedResourceClass($event)" />
    <app-property-form
      *ngFor="let propertyForm of asyncData.propertyFormList"
      [matchResourceClassesLoading]="asyncData.matchResourceClassesLoading"
      [resourcesSearchResultsLoading]="asyncData.resourcesSearchResultsLoading"
      [resourcesSearchResultsCount]="asyncData.resourcesSearchResultsCount"
      [resourcesSearchResults]="asyncData.resourcesSearchResults"
      [resourcesSearchNoResults]="asyncData.resourcesSearchNoResults"
      [propertyFormItem]="propertyForm"
      [properties]="asyncData.filteredProperties?.length ? asyncData.filteredProperties : asyncData.properties"
      [propertiesLoading]="asyncData.propertiesLoading"
      [selectedProperty]="propertyForm.selectedProperty"
      [selectedOperator]="propertyForm.selectedOperator"
      (emitRemovePropertyForm)="handleRemovePropertyForm($event)"
      (emitSelectedPropertyChanged)="handleSelectedPropertyChanged($event)"
      (emitSelectedOperatorChanged)="handleSelectedOperatorChanged($event)"
      (emitSelectedMatchPropertyResourceClassChanged)="handleSelectedMatchPropertyResourceClassChanged($event)"
      (emitSearchValueChanged)="handleSearchValueChanged($event)"
      (emitResourceSearchValueChanged)="handleResourceSearchValueChanged($event)"
      (emitLoadMoreSearchResults)="handleLoadMoreSearchResults($event)"
      (emitAddChildPropertyForm)="handleAddChildPropertyForm($event)"
      (emitRemoveChildPropertyForm)="handleRemoveChildPropertyForm($event)"
      (emitChildSelectedPropertyChanged)="handleChildSelectedPropertyChanged($event)"
      (emitChildSelectedOperatorChanged)="handleChildSelectedOperatorChanged($event)"
      (emitChildValueChanged)="handleChildValueChanged($event)" />
    <app-form-actions
      [addButtonDisabled]="asyncData.addButtonDisabled"
      [resetButtonDisabled]="asyncData.resetButtonDisabled"
      [searchButtonDisabled]="asyncData.searchButtonDisabled"
      (emitAddPropertyForm)="handleAddPropertyForm()"
      (emitResetButtonClicked)="handleResetButtonClicked()"
      (emitSearchButtonClicked)="handleSearchButtonClicked()" />
  </div>
</div>
